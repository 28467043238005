<template>
  <v-dialog
    v-model="dialog"
    hide-overlay

    width="auto"
    content-class="custom-alert-dialog"
  >
    <v-alert
      dense
      :type="config.type"
      @click="$refs.alert_pop_up.showPopUp()"
    >
      {{ config.message }}
    </v-alert>
  </v-dialog>
</template>

<script>
  export default {
    data: () => {
      return {
        dialog: false,
        config: {}
      }
    },
    methods: {
      showPopUp(config) {
        this.config = config
        this.dialog = true
      }
    },
    computed: {
    },
    watch: {
      dialog (val) {
        if (!val) return

        setTimeout(() => (this.dialog = false), 4000)
      },
    },
  }
</script>

<style>
  .custom-alert-dialog {
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .v-alert{
    margin-bottom: 0px;
  }
</style>
