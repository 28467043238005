<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->


      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none py-0 px-0 d-lg-block"

        >
        <div style="align-items: center; justify-content: center;  display: flex;  width: 100%; height: 100%;  -webkit-align-items: center;">
          <v-img :src="require(`@/assets/images/banner-login.png`)" style="max-width:500px;"></v-img>
        </div>

        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"

            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                  {{$t('¿Olvidaste tu contraseña?')}}
                  </p>
                  <p class="mb-2">
                    {{$t('Ingresa tu dirección de email y te enviaremos un link de recuperación')}}
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="forgotPassword" @submit.prevent="sendEmail">
                    <v-text-field
                      v-model="form.email"
                      outlined
                      label="Email"
                      placeholder="Email"
                      hide-details="auto"
                      :rules="emailRules"
                      class="mb-6"
                    ></v-text-field>

                    <v-row>
                      <v-col cols="6">
                        <v-btn
                          block
                          color="info"
                          class="mt-6"
                          @click="() => $router.push('/login')"
                        >
                          {{$t('Volver')}}
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                        block
                        :disabled="loading"
                        color="primary"
                        type="submit"
                        class="mt-6"
                        >
                          <div v-if="!loading">{{$t('Enviar email')}}</div>
                          <v-progress-circular
                            v-if="loading"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <alert-pop-up ref="alert_pop_up"/>
  </div>
</template>

<script>
import router from '@/router'
import AlertPopUp from '@/components/AlertPopUp.vue';

export default {
  data: () => {
    return {
      form:{
        email:''
      },
      emailRules: [
        v => !!v || 'El email es obligatorio',
      ],
      emailError: false,
      loading: false
    }
  },
  components: {
    AlertPopUp,
  },
  methods: {
    async sendEmail() {
      if(this.$refs.forgotPassword.validate()) {
        this.loading = true
        try {
          const response = await this.$api.sendResetPasswordEmail(this.form)
          if(response.status === 'OK') {
            this.$refs.alert_pop_up.showPopUp({ message: this.$t('Link de recuperación enviado a la dirección de email ingresada.'), type: 'success' })
          }
        } catch(e) {
          console.log(e.response)
          if(e?.response?.status === 400) {
            this.$refs.alert_pop_up.showPopUp({ message: this.$t('No existe usuario vinculado con el email ingresado.'), type: 'error' })
          }
        } finally {
          this.loading = false
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
